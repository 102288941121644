import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import NumberGroupEntry, { NumberGroupEntryShape } from './NumberGroupEntry'
import { breakpoint } from 'ui/elements/ThemeProvider/utils';

const Box = styled.div`
  display: flex;
  flex-direction: column;

  margin: 20px 0;
  padding: 0 20px;

  box-sizing: border-box;

  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;

  ${breakpoint.md(css`
    width: 50%;
  `)}

  ${breakpoint.lg(css`
    ${props => {
      switch (props.perRow) {
        case 2:
          return css`
            width: 50%;
          `
        case 4:
          return css`
            width: 25%;
          `
        case 3:
        default:
          return css`
            width: 33%;
          `
      }
    }}
  `)}
`
const Title = styled.span`
  display: block;
  color: ${props => props.theme.palette.primary};
  font-size: 1.4em;
  line-height: 1.0;
  font-weight: bold;

  margin-bottom: 5px;
`
const Subtitle = styled.span`
  display: block;
  font-size: 1.4em;
  line-height: 1.0;
  font-weight: 300;
  color: ${props => props.theme.palette.primary};

  margin-bottom: 1em;
`
const NumberWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;

  padding: 8px 16px;

  border-right: 0;
  border-bottom: 0;

  border-left: 1px;
  border-top: 1px;
  border-style: solid;
  border-color: ${props => props.theme.palette.primary};

  margin-top: auto;
  margin-bottom: 0;
`

export const NumberGroupShape = PropTypes.shape({
  _key: PropTypes.string.isRequired,
  _type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  numbers: PropTypes.arrayOf(NumberGroupEntryShape).isRequired,
})

const NumberGroup = ({ group, perRow }) => {
  return <Box perRow={perRow}>
    <Title>{group.title}</Title>
    {!!group.subtitle && <Subtitle>{group.subtitle}</Subtitle>}
    <NumberWrap>
      {group.numbers.map((number) => {
        return <NumberGroupEntry
          number={number}
          key={number._key}
        />
      })}
    </NumberWrap>
  </Box>
}
NumberGroup.propTypes = {
  group: NumberGroupShape.isRequired,
  perRow: PropTypes.number.isRequired,
}

export default NumberGroup
