import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import NumberGroup, { NumberGroupShape } from './NumberGroup'

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;

  margin-left: -16px !important;
  margin-right: -16px !important;

  flex-wrap: wrap;
`

const NumberGroups = ({ groups, perRow }) => {
  return <Wrap>
    {groups.map((group) => {
      return <NumberGroup
        group={group}
        perRow={perRow}
        key={group._key}
      />
    })}
  </Wrap>
}

NumberGroups.propTypes = {
  groups: PropTypes.arrayOf(NumberGroupShape).isRequired,
  perRow: PropTypes.number,
}

NumberGroups.defaultProps = {
  perRow: 3,
}

export default NumberGroups
