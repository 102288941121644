import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpoint } from 'ui/elements/ThemeProvider/utils';

const NumberWrap = styled.div`
  color: ${props => props.theme.palette.shade};
  padding: 0 10px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  ${breakpoint.md(css`
    padding: 10px;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  `)}
`
const NumberContainer = styled.div`
  font-weight: bold;
  line-height: 1;
`
const NumberSymbol = styled.span`
  font-size: 36px;

  ${breakpoint.md(css`
    font-size: 48px;
  `)}

  ${breakpoint.lg(css`
    font-size: 60px;
  `)}
`
const Number = styled.span`
  font-size: 48px;

  ${breakpoint.md(css`
    font-size: 60px;
  `)}

  ${breakpoint.lg(css`
    font-size: 72px;
  `)}
`
const Subtitle = styled.span``

export const NumberGroupEntryShape = PropTypes.shape({
  _key: PropTypes.string.isRequired,
  _type: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
})

const NumberGroupEntry = ({ number }) => {
  return <NumberWrap>
    <NumberContainer>
      {!!number.prefix && <NumberSymbol>{number.prefix}</NumberSymbol>}
      <Number>{number.number}</Number>
      {!!number.suffix && <NumberSymbol>{number.suffix}</NumberSymbol>}
    </NumberContainer>
    {number.subtitle && <Subtitle>{number.subtitle}</Subtitle>}
  </NumberWrap>
}
NumberGroupEntry.propTypes = {
  number: NumberGroupEntryShape,
}

export default NumberGroupEntry
